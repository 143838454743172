.documentsHeader{
  scroll-margin-top: 85px;
}

.anchor{
    color: rgb(235, 235, 239);
    text-decoration: none
    ;
}

.container{
  height: 300px;
  overflow: scroll;
  padding:20px;
  background-color:rgb(37, 34, 34);
  border-radius: 5px;
}

h2 {
  margin-bottom: 0px;
}

h4{
  margin:0;
  color: rgb(236, 229, 229);
}

.link:hover{
    color:#d96227;
}

.card{
  border-radius: 5px; 
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.6);
  background-color:rgb(37, 34, 34);
}

