.carousel {
  width: 100%;
  height: 800px;
  background-color: black;
}

.carouselInner {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  opacity: 1;
  animation-name: bgFadeIn;
  animation-duration: 1s;  
}

.carouselInner .left {
  flex: 5%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.1);
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
}

.carouselInner .right {
  flex: 5%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.1);
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
  
}

.carouselInner .center {
  flex: 90%;
  height: 100%;
}

.carouselInner h1 {
  color: white;
  text-align: left;
  margin: 0;
}

.carouselInner p {
  color: #0076BD;
  text-align: left;
  margin-top: 0;
  font-weight: bold;
}

.dropInDiv { 
  animation-name: textIn;
  animation-duration: 4s;
  position: relative;
  top: 50px;
  margin-top: 200px;
}

@keyframes textIn {
  0% {
    left: 0px;
    top: 0px;
    opacity: 0;
  }
  100% {
    top: 50px;
    opacity: 1;
  }
}

@keyframes bgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
