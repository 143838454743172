.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-image: "https://aci-website.s3.us-east-2.amazonaws.com/Images/ACIBG.jpg";
  background-position: center;
  background-repeat: no-repeat;
  font-family: "Jost", Sans-serif;
  background-attachment: fixed;
}


.background {
  height: 100vh;
  width: 100vw;
  background-image: url("https://aci-website.s3.us-east-2.amazonaws.com/Images/ACIBG.jpg");
  background-size: "cover";
  background-clip: fixed;
}

.container1 {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

/*backgroundImage: `URL(${background})`,backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", position: "fixed", height: "100vh", width: "100vw"}}*/

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.my-container div  {
  background: rgb(154, 54, 54);
  border-radius: 30px;
  width: 150px;
  height: 150px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

ul {
  display: inline;
  text-decoration: none;
}