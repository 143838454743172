.whoWeAreHeader{
    scroll-margin-top: 100px;
  }

a {
    color:white;
    text-decoration: none;
}

a:hover{
    color:white;
}