.footer {
  width: 100%;
  background-color: #d96227;
  color: white;
}

p {
  color: white;
  text-align: left;
}

.footer a {
  color: white;
  text-align: left;
}
