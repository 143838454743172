.searchRepair {
  background-color: #fff;
  /* scroll-margin-top: 90px; */
  
}

.searchRepairDiv{
  color: black;
  font-size: 22px;
}
